body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sui-result {
  padding-top: 20px !important;
  padding-bottom: 10px !important;
}

dl.vendor-result {
  margin: 0;
  clear: both;
}

dl.vendor-result dt,
dl.vendor-result dd {
  float: left;
  display: block;
  margin-inline-start: 0;
  margin: 0 0 8px 0;
}

dl.vendor-result dt {
  font-weight: bold;
  color: gray;
  clear: both;
  width: 33%;
}

dl.vendor-result dd {
  font-weight: normal;
  width: 66%;
}

dl.vendor-result dt:after {
  content: ":"
}

@media only screen and (max-width: 640px) {
  dl.vendor-result dt,
  dl.vendor-result dd {
    float: none;
    width: 100%;
    display: block;
  }

  dl.vendor-result dt {
    margin: 0;
  }

  dl.vendor-result dd {
    margin-inline-start: 1em;
  }
}
